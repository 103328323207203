window.faqUrl = process.env.FAQ_PUBLIC_PATH;
window.contextsUrl = process.env.CONTEXTS_PUBLIC_PATH;
window.shellUrl = process.env.SHELL_PUBLIC_PATH;
window.recordingListUrl = process.env.RECORDING_LIST_PUBLIC_PATH;
window.boostupUrl = process.env.BOOSTUP_PUBLIC_PATH;
window.recordingPickerUrl = process.env.RECORDING_PICKER_PUBLIC_PATH;
window.loginRegisterUrl = process.env.LOGIN_REGISTER_PUBLIC_PATH;
window.allAppsUrl = process.env.ALL_APPS_PUBLIC_PATH;
window.recordingDetailsUrl = process.env.RECORDING_DETAILS_PUBLIC_PATH;
window.coachingUrl = process.env.COACHING_PUBLIC_PATH;
window.publicPagesUrl = process.env.PUBLIC_PAGES_PUBLIC_PATH;
window.externalIntegrationUrl = process.env.EXTERNAL_INTEGRATION_PUBLIC_PATH;
window.annotationToolUrl = process.env.ANNOTATION_TOOL_PUBLIC_PATH;
window.callAIShellUrl = process.env.CALL_AI_SHELL_PUBLIC_PATH;
window.remoteEntryFileName = process.env.REMOTE_ENTRY_FILENAME;
window.callAIAdminUrl = process.env.CALLAI_ADMIN_PUBLIC_PATH;
